.heroContainer {
    height: 90vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('../../assets/heroPic.jpg');
    background-size: cover;
    background-position: bottom 0px right -135px;
}

.heroTextContainer {
    width: 90%;
    position: absolute;
    top: 47%;
    left: 4%;
    color: white
}

.heroTitle {
    font-family: 'Heavy';
    letter-spacing: 1px;
}

.heroSubtitle {
    font-family: 'Regular';
    letter-spacing: 1px;
}

.itemContainer {
    border-radius: 20px;
    padding: 10px 40px;
    background-color: #ffffffd6;
    margin: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.5px 1.5px 2px;
}

.itemContainer:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 2.5px 2.5px 5px;
    padding: 9px 38px;
}

.itemContainer:hover>p {
    font-weight: bold;
}

.infoImg {
    width: 10vw;
}

.infoText {
    font-weight: 500;
    font-size: 0.9em;
}

.container {
    margin: 100px 0;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.subImg {
    height: auto;
    max-width: 100%;
    border-radius: 20px;
    margin: -150px 0 20px 0
}

.subText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.subtitle {
    font-family: "Catamaran";
    letter-spacing: 2px;
    font-size: 0.8em;
    font-weight: 600;
    margin: 0.3em 0;
    opacity: 0.6;
}

.descTitle {
    font-family: 'Cormorant';
    font-size: 38px;
    line-height: 40px;
    margin: 0
}

.descText {
    line-height: 1.8em;
    opacity: 0.9;
}

.button {
    padding: 10px 20px;
    border: solid 1.5px rgba(0, 0, 0, 0.537);
    font-family: "Catamaran";
    opacity: 0.85;
    font-size: 0.75em;
    margin: 0;
    height: 20px;
    text-decoration: none;
    color: black;
    margin-right: 15px;
}

.button:hover {
    opacity: 1;
    border: solid 1.5px black;
    font-weight: 600;
    cursor: pointer
}

.irinaImg {
    border-radius: 20px;
    width: 90%;
    margin-bottom: 20px;
}

.googleMaps {
    width: 100vw;
    height: 25vh
}

.markerImg {
    width: 20vw;
    height: 20vw;
    margin-top: -20vw;
    margin-left: -10vw;
}

.custom-shape-divider-top-1725819627 {
    width: 110%;
    line-height: 0;
    margin-top: -1px;
}

.custom-shape-divider-top-1725819627 svg {
    position: relative;
    display: block;
    width: 110%;
    height: 80px;
    transform: rotateY(180deg);
    z-index: 30
}

.custom-shape-divider-top-1725819627 .shape-fill {
    fill: #231A2E;
}

/* PC */

@media screen and (min-width: 500px) {

    .heroContainer {
        height: calc(100vh + 20px);
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.17)), url('../../assets/heroPic.jpg');
        background-size: cover;
        background-position: center -25px;
    }

    .heroTextContainer {
        width: 70%;
        position: absolute;
        top: 48vh;
        left: 3.5vw;
        color: white
    }

    .coverPic {
        width: 80%;
        height: 650px;
    }

    .itemsOverlay {
        height: 160px;
        width: 80%;
        position: relative;
        top: -180px;
        left: 0;
        border-radius: 0 0 20px 20px;
        background-color: #ffffffd6;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .itemContainer {
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        margin: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: none;
    }

    .itemContainer:hover {
        box-shadow: none;
    }

    .itemContainer:hover>p {
        font-weight: 500;
    }

    .infoImg {
        width: 5vw;
    }

    .infoText {
        font-weight: 500;
        font-size: 0.9em;
    }

    .subText {
        padding: 30px;
    }

    .subImg {
        height: auto;
        max-width: 45%;
        border-radius: 20px;
        margin: 0px -180px 0 70px
    }

    .descText {
        font-size: 1.0em;
    }

    .irinaImg {
        border-radius: 20px;
        width: 35%;
        margin-bottom: 20px;
        margin-right: 40px;
    }

    .markerImg {
        width: 6vw;
        height: 6vw;
        margin-top: -6vw;
        margin-left: -3vw;
    }

    .googleMaps {
        width: 100%;
        height: 240px;
    }
}

/* Effects */

.highlight {
    animation-name: highlight;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    background-size: 200%;
    background-image: linear-gradient(to right, #EFEFED 50%, transparent 50%),
        linear-gradient(transparent 10%, #B9CFBF 10%);
}

.bounce {
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.bounceInUp {
    animation-name: zoomIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}

.flipInX {
    animation-name: flipInX;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.rotateIn {
    animation-name: rotateIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.zoomIn {
    animation-name: animate__zoomIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes highlight {
    from {
        background-position: 0;
    }

    to {
        background-position: -100%;
    }
}