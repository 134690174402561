.navbar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
}

.navbarLogoContainer {
    width: 44vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarButtonsContainer {
    width: 25vw;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: -5px;
}

.navbarBurgerContainer {
    display: flex;
    justify-content: end;
    align-items: top;
    margin-top: 0px;
    width: 25vw;
}

.navbarNavContainer {
    display: flex;
    justify-content: center;
}

.logo {
    margin-top: -3px;
    height: 32px;
    object-fit: cover;
}

.hover-underline-animation,
.navTitle {
    position: relative;
    flex: 1;
    color: black;
    background-color: transparent;
    font-size: 0.7em;
    margin: 0 2px;
    cursor: default;
    letter-spacing: 0.5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.navTitle>a {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 4px;
    color: black;
}

.hover-underline-animation>a {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 4px;
    color: black;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.underline {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: black;
}

a {
    text-decoration: none;
}

.navbarSideContainer>a {
    margin: 5px 0
}

/* PC */

@media screen and (min-width: 500px) {

    .navbar {
        height: 100px;
        flex-direction: row;
        justify-content: space-around;
    }

    .hover-underline-animation,
    .navTitle {
        letter-spacing: 3px;
        margin: 0 8px;
    }

    .navTitle>a {
        font-size: 1.075em;
        font-family: 'Heavy';
    }

    .hover-underline-animation>a {
        font-size: 1.075em;
        font-family: 'Heavy';
    }

    .navbarNavContainer {
        width: 57vw;
    }

    .navbarLogoContainer {
        width: 18vw
    }

    .navbarButtonsContainer {
        justify-content: end;
        width: 25vw
    }

    .logo {
        margin-top: -4px;
        height: 43px
    }

    .hover-underline-animation,
    .navTitle {
        font-size: 1.075em;
        margin: 0 40px;
        flex: 0 1 auto
    }

}


@keyframes wipe-enter {
    0% {
        transform: scale(0, .025);
    }

    50% {
        transform: scale(1, .025);
    }
}