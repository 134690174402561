.heroPatternStars {
    height: 600px;
    width: 50%;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231a237e' fill-opacity='0.57' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3C/g%3E%3C/svg%3E");
}

.heroPatternStarsH {
    height: 200px;
    width: 100vw;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231a237e' fill-opacity='0.57' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3C/g%3E%3C/svg%3E");
}

.itemContainer {
    border-radius: 20px;
    padding: 10px 40px;
    background-color: #ffffffd6;
    margin: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.5px 1.5px 2px;
}

.itemContainer:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 2.5px 2.5px 5px;
    padding: 9px 38px;
}

.itemContainer:hover>p {
    font-weight: bold;
}

.infoImg {
    width: 10vw;
}

.infoText {
    font-weight: 500;
    font-size: 0.9em;
}

.container {
    margin: 100px 0;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.subImg {
    height: auto;
    max-width: 100%;
    border-radius: 20px;
    margin: -150px 0 20px 0
}

.subText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.subtitle {
    font-family: "Catamaran";
    letter-spacing: 2px;
    font-size: 0.8em;
    font-weight: 600;
    margin: 0.3em 0;
    opacity: 0.6;
}

.descTitle {
    font-family: 'Cormorant';
    font-size: 38px;
    line-height: 40px;
    margin: 0
}

.descText {
    line-height: 1.8em;
    opacity: 0.9;
}

/* input[type='range'] {
    width: 100%;
    margin: 0;
    accent-color: #3F51B5
} */

/* input[type='range']::-webkit-slider-thumb {
    cursor: pointer;
    background-color: #3F51B5;
}

input[type="range"]::-webkit-slider-thumb {
    transition: background 0.3s ease-in-out;
} */

.button {
    padding: 10px 20px;
    border: solid 1.5px rgba(0, 0, 0, 0.537);
    font-family: "Catamaran";
    opacity: 0.85;
    font-size: 0.75em;
    margin: 0;
    height: 20px;
    text-decoration: none;
    color: black;
    margin-right: 15px;
}

.button:hover {
    opacity: 1;
    border: solid 1.5px black;
    font-weight: 600;
    cursor: pointer
}

.irinaImg {
    border-radius: 20px;
    width: 90%;
    margin-bottom: 20px;
}


.markerImg {
    width: 20vw;
    height: 20vw;
    margin-top: -20vw;
    margin-left: -10vw;
}

/* PC */

@media screen and (min-width: 500px) {
    .heroPatternStars {
        height: 600px;
        width: 200px;
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231a237e' fill-opacity='0.57' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3C/g%3E%3C/svg%3E");
    }

    .heroPatternStarsH {
        height: 200px;
        width: 600px;
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231a237e' fill-opacity='0.57' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3C/g%3E%3C/svg%3E");
    }

    .coverPic {
        width: 80%;
        height: 650px;
    }

    .itemsOverlay {
        height: 160px;
        width: 80%;
        position: relative;
        top: -180px;
        left: 0;
        border-radius: 0 0 20px 20px;
        background-color: #ffffffd6;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .itemContainer {
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        margin: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: none;
    }

    .itemContainer:hover {
        box-shadow: none;
    }

    .itemContainer:hover>p {
        font-weight: 500;
    }

    .infoImg {
        width: 5vw;
    }

    .infoText {
        font-weight: 500;
        font-size: 0.9em;
    }

    .subText {
        padding: 30px;
    }

    .subImg {
        height: auto;
        max-width: 45%;
        border-radius: 20px;
        margin: 0px -180px 0 70px
    }

    .descText {
        font-size: 1.0em;
    }

    .irinaImg {
        border-radius: 20px;
        width: 35%;
        margin-bottom: 20px;
        margin-right: 40px;
    }

    .markerImg {
        width: 6vw;
        height: 6vw;
        margin-top: -6vw;
        margin-left: -3vw;
    }
}

/* Effects */

.highlight {
    animation-name: highlight;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    background-size: 200%;
    background-image: linear-gradient(to right, #EFEFED 50%, transparent 50%),
        linear-gradient(transparent 10%, #B9CFBF 10%);
}

.bounce {
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.flip {
    animation-name: flip;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.bounceInUp {
    animation-name: zoomIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.zoomIn {
    animation-name: animate__zoomIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.slideInRight {
    animation-name: animate__slideInRight;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes highlight {
    from {
        background-position: 0;
    }

    to {
        background-position: -100%;
    }
}